import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxToastrService } from '../../services/toastr.service';
import { RequestService } from '../../services/request.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-delete-account',
  templateUrl: './delete-account.component.html',
  styleUrls: ['./delete-account.component.css']
})
export class DeleteAccountComponent implements OnInit {
  deleteForm: FormGroup;
  deleteOTPForm: FormGroup;
  deleteAccountForm: boolean = true;
  deleteAccountFormOtp: boolean = false;
  loading = false;
  submitted = false;
  error = '';
  success = '';
  delete_id: string = '';

  isLoading: boolean = false;

  constructor(
    private toastrService: NgxToastrService,
    public requestService: RequestService,
    private formBuilder: FormBuilder,
    public router: Router,
  ) {
    this.deleteForm = this.formBuilder.group({
      name: ['', Validators.required],
      mobile_number: ['', Validators.required],
      reason: ['', Validators.required]
    });
    this.deleteOTPForm = this.formBuilder.group({
      otp: ['', Validators.required],
    });
  }

  ngOnInit() {

  }

  get f() { return this.deleteForm.controls; }
  get p() { return this.deleteOTPForm.controls; }

  deleteAccount() {
    this.submitted = true;
    this.isLoading = true;
    // stop here if form is invalid
    if (this.deleteForm.invalid) {
      this.isLoading = false;
      return;
    }
    this.loading = true;
    this.requestService.addDeleteRequest(this.deleteForm.value)
      .pipe(first())
      .subscribe({
        next: (data) => {
          console.log("data",data)
          if(data.status === 1){
            this.delete_id = data.data._id;
            this.deleteAccountForm = false;
            this.deleteAccountFormOtp = true;
            this.toastrService.showSuccess('Success!', data.message);
          } else {
            this.toastrService.showError('Error!', data.message);
          }
          this.loading = false;
          this.isLoading = false;
        },
        error: error => {
          this.toastrService.showError('Error!', error);
          this.loading = false;
          this.isLoading = false;
        }
      });
  }

  deleteAccountOTP() {
    this.submitted = true;
    this.isLoading = true;
    // stop here if form is invalid
    if (this.deleteOTPForm.invalid) {
      this.isLoading = false;
      return;
    }
    this.loading = true;
    this.requestService.verifyDeleteRequest({delete_id: this.delete_id, otp: this.deleteOTPForm.value.otp})
      .pipe(first())
      .subscribe({
        next: (data) => {
          console.log("data",data)
          if(data.status === 1){
            this.toastrService.showSuccess('Success!', data.message);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            this.toastrService.showError('Error!', data.message);
          }
          this.loading = false;
          this.isLoading = false;
        },
        error: error => {
          this.toastrService.showError('Error!', error);
          this.loading = false;
          this.isLoading = false;
        }
      });
  }
}
